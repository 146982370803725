.submitbutton {
    font-family: "Century Gothic", Futura, sans-serif;
    color: red;
    background-color: darkcyan;
    border: darkcyan;
    font-size: 18px;
    justify-content: right;
    cursor: pointer;
}
  
/* border-radius: 4px; */
