.stop-watch{
	height: 16vh;
	width: 100%;
	background-color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 50px 0px 50px 0px;
}
.btn-grp {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-bottom: 50px;
}
	
.btn {
font-family: Verdana, Geneva, Tahoma, sans-serif;
font-size: 16px;
width: 20vw;
height: 30px;
border-radius: 4px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
color: #f5f5f5;
margin: 10px;
}
	
.btn-one{
	background-color: darkcyan;
	color: white;
}
	
