.timer{
	margin : 20px 5px 50px 5px;
	width: 100%;
	display: flex;
	height: 30%;
	justify-content: center;
	align-items: center;
}

.digits{
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: 110px;
	color: aqua;
}

.mili-sec{
	color: aqua;
}
