.btn {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px !important;
    height: 5vh;
    border-radius: 4px;
    display:flex;
    width: 25vw !important;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    margin: 10px;
    background-color: darkcyan;
    border: darkcyan;
    color: red;
    }
      
.exerbtn {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    width: 40vw;
    height: 5vh;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    background-color: darkcyan;
    border: darkcyan;
    color: white;
    }
        
  
